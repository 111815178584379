<template>
    <panel-alert title="编辑" isShowBottom width="680px" @onCloseClick="onCloseClick" @onConfirmClick="onConfirmClick">
        <template v-slot:content>
            <div class="content">
                <div v-for="(item, index) in reverseData" :key="item.id">
                    <div class="question" v-if="canShow(item, index)">
                        {{ `${item.problemNumber}${item.problemNumber == '' ? '' :
                            '.'}${questionTitle(item.problemTitle)[0]}`
                        }}
                        <template v-if="item.answerType === 1">
                            <el-select v-model="answerList[index]" :popper-append-to-body="false" collapse-tags
                                placeholder="请选择">
                                <el-option v-for="(answer, index) in item.answerList" :key="answer.id"
                                    :label="answer.answer" :value="answer.id">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-if="item.answerType === 8">
                            <el-input v-model="answerList[index]" placeholder="请输入"></el-input>
                        </template>
                        <template v-if="item.answerType === 9">
                            <el-time-select v-if="item.id !== 24" placeholder="请选择" v-model="answerList[index]"
                                :picker-options="{
                                    start: `0:00`,
                                    step: '00:15',
                                    end: `12:00`
                                }" :default-value="defaultArr[`${item.id}`]" :append-to-body="false">
                            </el-time-select>
                            <el-select v-else v-model="answerList[index]" :popper-append-to-body="false" collapse-tags
                                placeholder="请选择" clearable>
                                <el-option v-for="(option, index) in question24TimeOptions" :key="option.value"
                                    :label="option.label" :value="option.value">
                                </el-option>
                            </el-select>
                        </template>
                        {{ `${questionTitle(item.problemTitle)[1]}` }}
                    </div>
                </div>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import { DateTool } from "@js/date-tool.js";
import {
    Input,
    DatePicker,
    TimePicker,
    TimeSelect,
    Select,
    Option
} from "element-ui";

export default {
    components: {
        PanelAlert,
        //element
        elInput: Input,
        elDatePicker: DatePicker,
        elSelect: Select,
        elOption: Option,
        ElTimePicker: TimePicker,
        ElTimeSelect: TimeSelect,
    },

    props: {
        data: Array,
        type: Number,
    },

    watch: {
        data: {
            immediate: true,
            handler(newVal) {
                this.reverseData?.forEach((el, index) => {
                    if (el.userAnswer) {
                        if (el.answerType === 1) {
                            this.answerList[index] = el.userAnswer.singleChoiceAnswerId
                        } else if (el.answerType === 8) {
                            this.answerList[index] = el.userAnswer.bigTextInput
                        } else if (el.answerType === 9) {
                            this.answerList[index] = el.userAnswer.timeInput
                        } else {
                            this.answerList[index] = el.userAnswer.singleChoiceAnswerId
                        }
                    } else {
                        this.answerList[index] = null
                    }

                })

            }
        },
        type: {
            immediate: true,
            handler(newVal) {
                if (newVal === 6) {
                    this.inputWidth = 180
                }
            }
        }
    },

    computed: {
        reverseData() {
            let tag = 0
            return this.data?.sort((a, b) => a.id - b.id).map((el, index) => {
                if (el.id !== 38) {
                    return {
                        ...el,
                        problemNumber: `${index + 1 + tag}`
                    }
                } else {
                    tag--;
                    return {
                        ...el,
                        problemNumber: ''
                    }
                }
            })
        }
    },
    data: function () {
        return {
            answerList: [],
            //24题 18:00-6:00, 26、27 0:00-12:00
            defaultArr: {
                "24": "21:00",
                "26": "07:00",
                "27": "07:00"
            },
            question24TimeOptions: [],
            inputWidth: 140,
        };
    },

    mounted() {
        for (let i = 18; i != 9; i = (i + 1) % 24) {
            const minutes = ['00', '15', '30', '45']
            if (i === 8) {
                this.question24TimeOptions.push({
                    value: '08:00',
                    label: '08:00'
                })
                break
            }
            for (let j = 0; j < 4; j++) {
                const minute = minutes[j];
                const value = `${String(i).padStart(2, '0')}:${minute}`
                this.question24TimeOptions.push({
                    value: value,
                    label: value
                })
            }
        }
    },

    methods: {
        questionTitle(title) {
            const questionList = title.split('_____')
            if (questionList.length > 1) {
                return [questionList[0], `${questionList[1]}`]
            } else {
                return [`${questionList[0]}`, '']
            }
        },

        canShow(item, index) {
            if (item.id === 38) {
                const question37AnswerIndex = this.reverseData.findIndex(el => el.id === 37)
                const question37AnswerId = this.answerList[question37AnswerIndex]
                const question37 = this.reverseData[question37AnswerIndex]
                const question37Answer = question37?.answerList?.find(a => a.id === question37AnswerId)
                if (question37Answer && question37Answer.havaChildren) {
                    return true
                } else {
                    return false
                }
            }
            return true
        },

        onCloseClick() {
            this.$emit("onCloseClick");
        },
        onConfirmClick() {
            for (let i = 0; i < this.answerList.length; i++) {
                const item = this.answerList[i]
                if (!item) {
                    const question = this.reverseData[i]
                    if (question.id === 38) {
                        const question37AnswerId = this.answerList[i - 1]
                        const question37 = this.reverseData.find(el => el.id === 37)
                        const question37Answer = question37?.answerList?.find(a => a.id === question37AnswerId)
                        if (!question37Answer || !question37Answer.havaChildren) {
                            continue
                        }
                    }
                    this.$toast.showRed('请填写完全部问题后再保存')
                    return
                }
            }
            const list = this.reverseData.map((el, index) => {
                // answer
                if (el.answerType === 1) {
                    return {
                        problemId: el.id,
                        singleChoiceAnswerId: this.answerList[index]
                    }
                } else if (el.answerType === 8) {
                    return {
                        problemId: el.id,
                        bigTextInput: this.answerList[index]
                    }
                } else if (el.answerType === 9) {
                    return {
                        problemId: el.id,
                        timeInput: this.answerList[index]
                    }
                } else {
                    return {
                        problemId: el.id,
                        singleChoiceAnswerId: this.answerList[index]
                    }
                }
            })
            this.$emit("onSucceed", list);
        }
    }
};
</script>
  
<style lang="scss" scoped>
@import "@css/theme/right-arrow.scss";

/deep/.panel-alert-content {
    padding: 0 0 10px;
}

/deep/.panel-alert-bottom {
    border: none;
}

.content {
    padding-left: 27px;
    max-height: 750px;
    overflow: scroll;
}

.question {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 22px;
    display: flex;
    align-items: center;

    &::before {
        content: '*';
        color: #F21E1E;
        font-size: 14px;
    }

    /deep/.el-input {
        width: calc(v-bind(inputWidth) * 1px);
    }

    /deep/.el-input__inner {
        font-size: 14px;
        height: 32px;
        width: calc(v-bind(inputWidth) * 1px);
    }

    /deep/.el-input__icon {
        line-height: 32px;
    }

    /deep/.el-select-dropdown__item {
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
            margin-left: 10px;
        }
    }
}
</style>